import React, { useEffect, useState } from 'react'
import {
  Center,
  Transition,
  Box,
  Text,
  Group,
  Overlay,
  SimpleGrid,
  Grid,
} from '@mantine/core'

import { useMediaQuery } from '@mantine/hooks'
import { useRouter } from 'next/router'
import { useGlobalUserLocation } from 'common/hooks/useGlobalUserLocation'
import { usePreviousSearch } from 'common/hooks/usePreviousSearch'

import { randomizeAds } from '../../lib/advertisingHelpers'

//New components below
import { LocationSearch2024 } from '../LocationSearch/LocationSearch2024'
import { HomeCategories } from './HomeCategories'
import { Advertising } from '../Ads/Advertising'
import Head from 'next/head'

import { renderMetaTags } from 'react-datocms'
import css from './HomeHeroLayout.module.css'

export default function HomeHeroLayout(props: any) {
  const [opened, setOpened] = useState(false)
  const allAds = props.data.allAds
  const router = useRouter()
  const globalLocation = useGlobalUserLocation()
  const previousSearch = usePreviousSearch()

  const dataDatoCMS = props.data.homepage.seoSettings
  const largeScreen = useMediaQuery('(min-width: 60em)')
  const [adsElements, setAdsElements] = useState<any[]>([])
  const [position, setPosition] = useState(0)

  let modifySearch = null

  if (router.query && (router.query.provider || router.query.location)) {
    const defaultSearch = {
      provider: JSON.parse(router.query.provider as string),
      location: JSON.parse(router.query.location as string),
    }
    modifySearch = defaultSearch
  } else {
    if (previousSearch.prevSearch && globalLocation.globalLocation) {
      const defaultSearch = {
        provider: previousSearch.prevSearch,
        location: globalLocation.globalLocation.locationText,
      }
      modifySearch = defaultSearch
    }
  }
  useEffect(() => {
    //create randomized ad array so each advertiser has the same odds of being seen first on every reload
    const ads = randomizeAds(allAds)
    setAdsElements(ads)
  }, [])

  useEffect(() => {
    setTimeout(() => {
      setOpened(true)
    }, 800)

    let intervalId: any
    let adPosition = 0
    const adLooper = () => {
      setOpened(false)
      adPosition = adPosition + 1

      if (adPosition === allAds.length) {
        adPosition = 0
        setTimeout(() => {
          setPosition(adPosition)
          setOpened(true)
        }, 3000)
      } else {
        setTimeout(() => {
          setPosition(adPosition)
          setOpened(true)
        }, 3000)
      }
    }

    intervalId = setInterval(adLooper, 15000)
    return () => clearInterval(intervalId)
  }, [])

  const handleSearch = (data: any, categoryId?: number) => {
    const parseProvider =
      data.provider && typeof data.provider === 'object'
        ? JSON.stringify(data.provider)
        : data.provider
    router.push(
      {
        pathname: '/search-results',
        query: {
          provider: parseProvider,
          location: JSON.stringify(data.location),
        },
      },

      '/search-results',
    )
  }
  return (
    <>
      <Head>{renderMetaTags(dataDatoCMS)}</Head>

      <SimpleGrid
        className={css.homeWrapper}
        style={{
          backgroundImage:
            'url(https://www.datocms-assets.com/51196/1711568686-edit_0.webp)',
          backgroundSize: 'cover',
          backgroundPosition: 'center',
        }}
      >
        <Overlay color="#000" opacity={0.145} zIndex={1} />

        <div className={css.inner}>
          <SimpleGrid>
            <Text size="lg" className={css.description} c="white">
              Find your passion on the water!
            </Text>

            <Box mx="xl" my="xs">
              <LocationSearch2024
                searchDefault={modifySearch ? modifySearch : null}
                onSearch={handleSearch}
              />
            </Box>
          </SimpleGrid>
          <SimpleGrid
            style={{
              height: largeScreen ? '60%' : '',
              alignItems: 'flex-end',
            }}
            px="md"
            mt="xs"
          >
            {largeScreen ? (
              <Transition
                mounted={opened}
                transition="fade"
                duration={2000}
                timingFunction="ease"
              >
                {(styles) => (
                  <Grid columns={2} style={styles}>
                    <Grid.Col span={1} offset={1}>
                      <Group grow position="right" mr="lg">
                        <Advertising
                          adDetails={adsElements[position]}
                          largeScreen={largeScreen}
                        />
                      </Group>
                    </Grid.Col>
                  </Grid>
                )}
              </Transition>
            ) : null}

            <Center>
              <HomeCategories onCategory={handleSearch} />
            </Center>
          </SimpleGrid>
        </div>

        {!largeScreen ? (
          <Transition
            mounted={opened}
            transition="slide-up"
            duration={1000}
            timingFunction="ease"
          >
            {(styles) => (
              <Box
                ml="lg"
                mr="lg"
                pb="3rem"
                style={styles}
                className={css.inner}
              >
                <Advertising
                  adDetails={adsElements[position]}
                  largeScreen={largeScreen}
                />
              </Box>
            )}
          </Transition>
        ) : null}
      </SimpleGrid>
    </>
  )
}
