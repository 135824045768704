import type { GetStaticProps, NextPage } from 'next'
import HomeHeroLayout from 'components/Home/HomeHeroLayout'
import { request } from 'common/lib/datocms'
import { RecoilRoot } from 'recoil'
import * as opensearch from 'web-app/lib/opensearch'

const client = opensearch.newClient()

export const getStaticProps: GetStaticProps = async (context) => {
  const isLocal = process.env.NODE_ENV
  const protocol = isLocal ? 'http://' : 'https://'
  // const host = context.req.headers.host
  // @ts-ignore
  const data = await request({
    query: `query Homepage {
      homepage {
        seoSettings: _seoMetaTags {
          attributes
          content
          tag
        }
      }
      allBannerAds {
        adImage {
          url
        }
        adTitle
        adText
        webLink
      }
      allAds {
        id
        name
        url
        image1 {
          id
          responsiveImage {
            alt
            src
          }
        }
        image2 {
          id
          responsiveImage {
            alt
            src
          }
        }
      }
    }`,
  })

  const response = await opensearch.getPremiumProviders(client)

  const hits = response.body?.hits?.hits

  const resList = Array<any>()
  hits.map((hit: any) => {
    resList.push({
      id: hit._id,
      source: hit._source,
    })
  })

  //The 3-lines below will fetch from Insta when we need to display on HomePage
  // const url = `https://graph.instagram.com/me/media?fields=id,caption,media_url,timestamp,media_type,permalink&access_token=${process.env.INSTAGRAM_TOKEN}`
  // const req = await fetch(url)
  // const feed = await req.json()

  // const idReq = `https://graph.instagram.com/me?fields=id,username&access_token=${process.env.INSTAGRAM_TOKEN}`
  // const userFetch = await fetch(idReq)
  // const userId = await userFetch.json()

  // const test = `https://graph.facebook.com/v3.2/6323993354393596?fields=business_discovery.username(boaterslist){followers_count,media_count}&access_token=${process.env.INSTAGRAM_TOKEN}`
  // const testFetch = await fetch(test)
  // const testRes = await testFetch.json()
  // console.log('TTT', testRes)
  // const bizIdUrl = `https://graph.facebook.com/${userId.id}?fields=instagram_business_account&access_token=${process.env.INSTAGRAM_TOKEN}`
  // const bizIdFetch = await fetch(bizIdUrl)
  // const bizId = await bizIdFetch.json()
  // console.log('ID', bizId)

  // console.log(typeof userIdNum)

  // const userId = 6323993354393596
  // console.log('ID', userId)
  // const hashtagUrl = `https://graph.facebook.com/v18.0/ig_hashtag_search?user_id=${userIdNum}&q=boaterslistatbisbees`
  // const hashReq = await fetch(hashtagUrl)
  // const hashtagRes = await hashReq.json()

  // console.log('HASH', hashtagRes)

  return {
    props: {
      data: data,
      premProviders: resList,
      // feed: feed,
    },
    revalidate: 120,
  }
}

const App: NextPage = (props: any) => {
  return (
    <RecoilRoot override={false}>
      <HomeHeroLayout data={props.data} premProviders={props.premProviders} />
    </RecoilRoot>
  )
}

export default App
