import React, { useState } from 'react'

import { Group, ScrollArea, Flex, Badge } from '@mantine/core'

import { useGlobalUserLocation } from 'common/hooks/useGlobalUserLocation'

import { categories } from 'constants/CategoryIcons'
import CatDrawer from 'components/Drawer/CatDrawer'

type CategoriesProps = {
  onCategory: (redirect: object) => void
}

export function HomeCategories({ onCategory }: CategoriesProps) {
  const [showAllCategories, setShowAllCategories] = useState(false)

  const globalLocation = useGlobalUserLocation()

  const onCategorySelect = (categoryId: string, label: string) => {
    if (categoryId) {
      onCategory({
        provider: { title: label, id: categoryId },
        location: globalLocation.globalLocation,
      })
    }
  }

  return (
    <>
      <CatDrawer
        opened={showAllCategories}
        onClose={() => setShowAllCategories(false)}
        onCategorySelect={onCategorySelect}
      />

      <ScrollArea pb="xl" styles={{ thumb: { backgroundColor: 'white' } }}>
        <Flex style={{ position: 'relative' }}>
          <Group style={{ flexWrap: 'nowrap', gap: '1.8rem' }}>
            {categories.map(({ label, fullLabel, icon, categoryId }, i) => (
              <Flex
                style={{ cursor: 'pointer' }}
                p="1.5px"
                direction="column"
                align="center"
                key={i}
                onClick={() => {
                  if (categoryId === 's-0') {
                    setShowAllCategories(true)
                    return
                  } else onCategorySelect(categoryId, fullLabel)
                }}
              >
                {icon}
                <Badge>{label}</Badge>
              </Flex>
            ))}
          </Group>
        </Flex>
      </ScrollArea>
    </>
  )
}
