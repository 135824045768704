export const randomizeAds = (allAds: any) => {
  const adsArr = []
  for (let i = allAds.length - 1; i >= 0; i--) {
    const j = Math.floor(Math.random() * (i + 1))
    //change var back to bannerAds once we implement the new ad layout from DatoCMS
    adsArr.push(allAds[j])
    const temp = allAds[i]
    allAds[i] = allAds[j]
    allAds[j] = temp
  }
  return adsArr
}
