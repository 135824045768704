import React from 'react'

import { Drawer, Button, Accordion, Stack } from '@mantine/core'
import theme from '../../../common/theme'

import { Category, getAllCategories } from 'common/global/categories'

interface CatDrawerProps {
  opened: boolean
  onClose: () => void
  onCategorySelect: any
}

export default function CatDrawer({
  opened,
  onClose,
  onCategorySelect,
}: CatDrawerProps) {
  const allCategories: Category[] = getAllCategories()

  return (
    <>
      <div className="drawer">
        <Drawer opened={opened} onClose={onClose} title="All Categories">
          <Accordion variant="separated" radius="md">
            {allCategories.map((cat, index) => {
              return (
                <div key={index}>
                  <Accordion.Item
                    value={cat.categoryName ? cat.categoryName : ''}
                  >
                    <Accordion.Control
                      style={{ backgroundColor: theme.colors.brandLightGray }}
                    >
                      {cat.categoryName}
                    </Accordion.Control>
                    <Accordion.Panel>
                      <Stack>
                        <Button
                          fullWidth
                          key={index}
                          onClick={() =>
                            onCategorySelect(cat.categoryId, cat.categoryName)
                          }
                        >
                          Search {cat.categoryName}
                        </Button>
                        {cat.subCategories?.map((subCat, index) => {
                          return (
                            <div key={index}>
                              <Button
                                fullWidth
                                onClick={() =>
                                  onCategorySelect(
                                    cat.categoryId,
                                    subCat.subCategoryName,
                                  )
                                }
                              >
                                {subCat.subCategoryName}
                              </Button>
                            </div>
                          )
                        })}
                      </Stack>
                    </Accordion.Panel>
                  </Accordion.Item>
                </div>
              )
            })}
          </Accordion>
        </Drawer>
      </div>
    </>
  )
}
